import notifications from '@imt/vue-admin-menus/src/store/notifications';
import toolbox from '@imt/vue-toolbox/src/store';
import {vueVersioning} from '@imt/vue-versioning/src/store';
import Vue from 'vue';
import Vuex from 'vuex';

import builder from '@/store/builder';
import mapping from '@/store/mapping';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        builder,
        notifications,
        toolbox,
        mapping,
        vueVersioning,
    },
});
