import {asString} from '@imt/vue-list/src/utils/queryParams';
import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import dayjs from 'dayjs';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

export const actions = {
    async fetchDataMap(context, id) {
        const response = await authedAxios.get(
            `/datamaps/${id}/?include=versions.operations,versions.updated_by`,
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchDataMaps({commit}, {queryObj = {}} = {}) {
        const response = await authedAxios.get(
                `/datamaps/?include=versions.updated_by${asString(queryObj)}`,
            ),
            dataMaps = utils.dataFormatter.deserialize(response.data);

        commit('SET_DATA', {
            type: 'dataMaps',
            data: dataMaps,
        });

        commit('SET_DATA', {
            type: 'totalMapCount',
            data: response.data.meta.pagination.count
        });

        return dataMaps;
    },
    async createDataMap({dispatch}, data) {
        let response = await authedAxios.post(
            '/datamaps/',
            utils.dataFormatter.serialize({stuff: {type: 'DataMap', ...data}}),
        );

        await dispatch('fetchDataMaps');

        return utils.dataFormatter.deserialize(response.data);
    },
    async createDraft({dispatch}, versionId) {
        const response = await authedAxios.post(`/versions/${versionId}/draft/?include=operations,updated_by`);

        await dispatch('fetchDataMaps');

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchSets() {
        let response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_VINNA}/api/v1/sets/?include=task_set`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async updateVersion({dispatch}, {versionId, attributes}) {
        const data = {
                id: versionId,
                type: 'Version',
                ...attributes,
            },
            response = await authedAxios.patch(
                `/versions/${versionId}/?include=operations,updated_by`,
                utils.dataFormatter.serialize({stuff: data}),
            );

        await dispatch('fetchDataMaps');

        return utils.dataFormatter.deserialize(response.data);
    },
    async publishVersion({dispatch}, {versionId, date = null}) {
        const formattedDate = (date !== null) ? dayjs(date).format('YYYY-MM-DD') : null,
            data = {
                id: versionId,
                publishedAt: formattedDate,
                type: 'Version',
            },
            response = await authedAxios.patch(
                `/versions/${versionId}/?include=operations,updated_by`,
                utils.dataFormatter.serialize({stuff: data}),
            );

        await dispatch('fetchDataMaps');

        return utils.dataFormatter.deserialize(response.data);
    },
    async unschedulePublish({dispatch}, versionId) {
        const response = await authedAxios.patch(
            `/versions/${versionId}/?include=operations,updated_by`,
            utils.dataFormatter.serialize({
                stuff: {
                    id: versionId,
                    type: 'Version',
                    publishedAt: null,
                    major: null,
                },
            }),
        );

        await dispatch('fetchDataMaps');

        return utils.dataFormatter.deserialize(response.data);
    },
    async revertVersion({dispatch}, {versionId, date = null}) {
        const formattedDate = (date !== null) ? dayjs(date).format('YYYY-MM-DD') : null,
            data = {
                id: versionId,
                revertedAt: formattedDate,
                type: 'Version',
            },
            response = await authedAxios.patch(
                `/versions/${versionId}/?include=operations,updated_by`,
                utils.dataFormatter.serialize({stuff: data}),
            );

        await dispatch('fetchDataMaps');

        return utils.dataFormatter.deserialize(response.data);
    },
    async obsoleteDataMap({dispatch}, {dataMapId, date = null}) {
        const formattedDate = (date !== null) ? dayjs(date).tz('America/Chicago').format('YYYY-MM-DDTHH:mm:ssZ') : null,
            data = {
                id: dataMapId,
                obsoleteAt: formattedDate,
                type: 'DataMap',
            },
            response = await authedAxios.patch(
                `/datamaps/${dataMapId}/?include=versions.operations,versions.updated_by`,
                utils.dataFormatter.serialize({stuff: data}),
            );

        await dispatch('fetchDataMaps');

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteVersion({dispatch}, version) {
        await authedAxios.delete(`/versions/${version.id}/`);
        await dispatch('fetchDataMaps');
    },
    async deleteDataMap({dispatch}, dataMapId) {
        await authedAxios.delete(`/datamaps/${dataMapId}/`);
        await dispatch('fetchDataMaps');
    },
};


export const mutations = {
    SET_DATA(state, {type, data}) {
        state[type] = data;
    },
};

export const state = () => {
    return {
        admin: {
            sideMenu: {
                collapsed: true,
            },
        },
        dataMaps: [],
        totalMapCount: 0,
    };
};

export default {
    namespaced: true,
    state: state(),
    actions,
    mutations,
};
