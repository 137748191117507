import Vue from 'vue';
import VueAceEdit from 'vue-ace-edit';
import Vuex from 'vuex';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import '@/plugins/bootstrap-vue';
import '@/plugins/scroll-to';
import '@/plugins/font-awesome';
import '@/plugins/keyboard-shortcuts';
import '@/plugins/vee-validate';
import '@/plugins/vue-meta';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueAceEdit);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
