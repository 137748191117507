import {UUID_REGEX} from '@imt/vue-toolbox/src/utils/index';

export default [
    {
        path: '/',
        redirect: {
            name: 'admin',
        },
    },
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "MBase" */ '@/views/MBase.vue'),
        children: [
            {
                path: '',
                name: 'admin',
                redirect: {
                    name: 'admin.datamaps.list',
                },
            },
            {
                path: 'datamaps',
                name: 'admin.datamaps.list',
                component: () => import(/* webpackChunkName: "MDataMapList" */ '@/views/MDataMapList.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'admin.datamaps.add',
                        meta: {
                            title: 'Add Data Map',
                        },
                        component: () => import(/* webpackChunkName: "MDataMapAddModal" */ '@/components/MDataMapAddModal.vue'),
                    },
                    {
                        path: `:id(${UUID_REGEX})`,
                        name: 'admin.datamaps.edit',
                        meta: {
                            title: 'Edit Data Map',
                        },
                        component: () => import(/* webpackChunkName: "MDataMapOptionsModal" */ '@/components/MDataMapOptionsModal.vue'),
                    },
                ],
            },
            {
                path: `datamap-builder/:dataMapId(${UUID_REGEX})/versions/:versionId(${UUID_REGEX})/edit`,
                name: 'admin.datamaps.builder',
                meta: {
                    title: 'Datamap Builder',
                },
                component: () => import(/* webpackChunkName: "MDataMapBuilder" */ '@/views/MDataMapBuilder.vue'),
                children: [
                    {
                        path: 'load',
                        name: 'admin.datamaps.load',
                        component: () => import(/* webpackChunkName: "MDataMapLoadModal" */ '@/components/builder/MDataMapLoadModal.vue'),
                    },
                ]
            },
        ],
    },

    // Fallback
    {
        path: '*',
        name: 'not-found',
        meta: {
            title: 'Not Found',
        },
        component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
    },
];
