<template>
    <div
        id="app"
        class="container-fluid"
    >
        <h3
            v-if="!permissionsLoaded"
            class="datamap-loading-component text-muted"
        >
            loading...
        </h3>
        <RouterView v-else-if="permissionsLoaded && hasAccess" />
        <Forbidden v-else />
    </div>
</template>

<script>
    import modesMixin from '@imt/vue-kit-car/src/mixins/modes';
    import {mapState} from 'vuex';

    import authMixin from './mixins/auth';

    export default {
        components: {
            'Forbidden': () => import(/* webpackChunkName: "KCForbidden" */ '@imt/vue-kit-car/src/components/errors/Forbidden.vue'),
        },
        mixins: [
            authMixin,
            modesMixin,
        ],
        computed: {
            permissionsLoaded() {
                return !!this.user.id;
            },
            ...mapState('toolbox', [
                'permissions',
                'user',
            ]),
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"

    .datamap-loading-component
        display: flex
        align-items: center
        justify-content: center
        height: 100vh
        width: 100vw
</style>
