import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

export const getters = {
    actions(state, storeGetters) {
        return Object.entries(state.availableOperations).map(([key, operation]) => {
            state.availableOperations[key].helpWording = storeGetters._objectifyHelpWording(storeGetters._parseHelpWording(state.availableOperations[key].description));
            state.availableOperations[key].description = storeGetters._parseDescription(state.availableOperations[key].description)[0];

            return {
                ...operation,
            };
        });
    },
    _objectifyHelpWording: () => helpWording => {
        return mapValues(keyBy(helpWording, hw => {
            return hw.key;
        }), 'wording');
    },
    _parseDescription: () => description => {
        return description?.match(new RegExp(/^(([\w\W]+)\n\n)|[\w\W]+/, 'g'))?.map(m => m.trim()) || [''];
    },
    _parseHelpWording: () => description => {
        return description?.match(new RegExp(/(:\w+:\W+.+)\n/, 'g'))?.map(hw => {
            return {
                key: hw.split(': ')[0].substring(1),
                wording: hw.split(': ')[1].trim(),
            };
        }) || [];
    },
};

export const actions = {
    async runDatamap(context, {code, object, versionId}) {
        const data = {
            code,
            object,
            versionId
        };

        let response = await authedAxios.post(
            `results/`,
            utils.dataFormatter.serialize({stuff: {type: 'Result', ...data}},
            ));

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteOperation(context, operationId) {
        return await authedAxios.delete(`/operations/${operationId}/`);
    },
    async fetchOperations(context) {
        let response = await authedAxios.get(`/available-operations/`);

        context.commit('SET_DATA', {
            type: 'availableOperations',
            data: response.data.data,
        });

        return response;
    },
    async fetchVersion(context, id, queryParams = '') {
        if (!queryParams.length) {
            queryParams = `include=operations,updated_by`;
        }

        const response = await authedAxios.get(`/versions/${id}/?${queryParams}`);

        context.commit('SET_DATA', {
            type: 'version',
            data: utils.dataFormatter.deserialize(response.data),
        });

        return response;
    },
    async saveDataMapOperations(context) {
        context.commit('SET_DATA', {
            type: 'saving',
            data: true,
        });

        await context.state.version.operations.forEach((operation, index) => {
            context.dispatch('saveOperation', {
                ...operation,
                order: index,
            });
        });

        context.commit('SET_DATA', {
            type: 'hasChanges',
            data: false,
        });
    },
    async saveOperation(context, operation, queryParams = '') {
        let method = 'patch';

        context.commit('SET_DATA', {
            type: 'saving',
            data: true,
        });

        if (!operation.id) {
            method = 'post';
            operation.type = 'Operation';
            operation.version = {
                type: 'Version',
                id: `${context.state.version.id}`,
            };
        }

        const response = await authedAxios[method](`/operations/${operation.id ?
            operation.id + '/' :
            ''}?${queryParams}`, utils.dataFormatter.serialize({
            stuff: operation,
        }));

        return utils.dataFormatter.deserialize(response.data);
    },
    async saveVersion(context) {
        context.commit('SET_DATA', {
            type: 'saving',
            data: true,
        });

        let version = {
            ...context.state.version,
            type: 'Version',
            id: `${context.state.version.id}`,
            relationshipNames: ['dataMap', 'draftOf', 'createdBy', 'updatedBy'],
        };

        // drops operations key to prevent endless loop in jsona
        delete version.operations;
        const response = await authedAxios.patch(
            `/versions/${context.state.version.id}/`,
            utils.dataFormatter.serialize({stuff: version}),
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    updateOperations(context, operations) {
        context.commit('SET_VERSION_OPERATIONS', operations);
        context.commit('SET_DATA', {
            type: 'hasChanges',
            data: true,
        });
    },
};

export const mutations = {
    REMOVE_OPERATION(state, operationId) {
        const removeOperationAtIndex = state.version.operations.findIndex((o) => o.id === operationId);
        state.version.operations.splice(removeOperationAtIndex, 1);
    },
    REMOVE_OPERATION_AT_INDEX(state, index) {
        state.version.operations.splice(index, 1);
    },
    SET_DATA(state, {type, data}) {
        state[type] = data;
    },
    SET_FILTER(state, value) {
        state.filter = value;
    },
    SET_VERSION_OPERATIONS(state, operations) {
        state.version.operations = sortBy(operations, 'order');
    },
};

export const state = () => {
    return {
        availableOperations: {},
        filter: '',
        hasChanges: false,
        saving: false,
        version: {},
    };
};

export default {
    namespaced: true,
    state: state(),
    actions,
    getters,
    mutations,
};
