import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import {extend} from 'vee-validate';
import {
    required,
    max,
    regex,
} from 'vee-validate/dist/rules';
import Vue from 'vue';

extend('max', max);
extend('regex', regex);
extend('required', {
    ...required,
    message: 'This field is required',
});

extend('valid-path', (value) => {
    if (value[0] !== '.' && value[value.length - 1] !== '.') {
        return true;
    }

    return 'Field cannot start or end with a "."';
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
